import React from "react"
import styled from "styled-components"
import Box from "../elements/Box"
import theme from "../general/theme"
import {
  // Canon,
  // Trafalgar,
  // Paragon,
  // GreatPrimer,
  // DoublePica,
  // BodyCopy,
  SmallPrint,
  // Code,
} from '../typography';
const { gray6 } = theme.colours;

const StyledFooter = styled.footer`
  color: ${gray6};

  .copyright-container {
    padding-left: 3rem;
  }
`;


const Footer = () => (
  <StyledFooter>
    <Box className="copyright-container">
      <SmallPrint>© Agewell {new Date().getFullYear()}</SmallPrint>
    </Box>
  </StyledFooter>
);


export default Footer;






