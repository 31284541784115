/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"

import styled, { ThemeProvider } from 'styled-components';
import theme from "../general/theme";
import GlobalStyle  from "../general/GlobalStyle"
import ResetCss  from "../general/ResetCss"
import Header from "./Header"
import Footer from "./Footer"
import Main from "./Main"

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  // background-color: grey;
`;

const Layout = ({ children }) => {

  return (
    <ThemeProvider theme={theme}>
      <>
      <GlobalStyle /> 
      <ResetCss /> 
        <Wrap>
          <Header />
            <Main>{children}</Main>
          <Footer />
        </Wrap>
      </>
    </ThemeProvider>
  )
}

export default Layout
