const colours = {
  white: '#ffffff',
  black: '#000000',
  agewellRed: '#cf1f32',
  transparent: 'transparent',
  gray0: '#f8f9fa',
  gray0rgb: '248, 249, 250',
  gray1: '#f1f3f5',
  gray1rgb: '241, 243, 245',
  gray2: '#e9ecef',
  gray2rgb: '233, 236, 239',
  gray3: '#dee2e6',
  gray3rgb: '222, 226, 230',
  gray4: '#ced4da',
  gray4rgb: '206, 212, 218',
  gray5: '#adb5bd',
  gray5rgb: '173, 181, 189',
  gray6: '#868e96',
  gray6rgb: '134, 142, 150',
  gray7: '#495057',
  gray7rgb: '73, 80, 87',
  gray8: '#343a40',
  gray8rgb: '52, 58, 64',
  gray9: '#212529',
  gray9rgb: '33, 37, 41',
};

const fontFamilies = {
  navigation: 'Libre Franklin, Helvetica, Arial, sans-serif',
  heading: 'Libre Franklin, Helvetica, Arial, sans-serif',
  body: 'Libre Franklin, Helvetica, Arial, sans-serif',
  smallCap: 'Libre Franklin, Helvetica, Arial, sans-serif',
  code: 'Roboto Mono, monospace',
};

const linkStyle = {
  primary: {
    textDecoration: 'underline',
    textDecorationColor: `${colours.gray7}`,
  },
};

export default {
  textStyles: {
    canon: {
      as: 'h1',
      fontSize: [11, 12, 14],
      fontWeight: 500,
      lineHeight: ['3.2rem', '3.6rem', '5.6rem'],
      fontFamily: fontFamilies.heading,
    },
    trafalgar: {
      as: 'h1',
      fontSize: [6, 9, 12],
      lineHeight: ['2.4rem', '2.8rem', '4rem'],
      fontWeight: 500,
      fontFamily: fontFamilies.heading,
    },
    paragon: {
      as: 'h2',
      fontSize: [6, 8, 11],
      fontWeight: 500,
      fontFamily: fontFamilies.heading,
      lineHeight: ['2rem', '2.6rem', '3.2rem'],
    },
    doublePica: {
      as: 'h3',
      fontSize: [6, 6, 10],
      fontWeight: 400,
      fontFamily: fontFamilies.heading,
      lineHeight: ['2.4rem', '2.4rem', '3rem'],
    },
    greatPrimer: {
      as: 'h3',
      fontWeight: 400,
      fontSize: [5, 5, 7],
      fontFamily: fontFamilies.heading,
      lineHeight: ['2.2rem', '2.2rem', '2.4rem'],
    },
    bodyCopy: {
      as: 'p',
      fontSize: [3, 4, 5],
      fontFamily: fontFamilies.body,
      lineHeight: ['2rem', '2.2rem', '2.4rem'],
    },
    smallCap: {
      as: 'p',
      fontWeight: 700,
      fontSize: [0, 0, 1],
      fontFamily: fontFamilies.smallCap,
      lineHeight: ['2rem', '2.2rem', '2.4rem'],
    },
    smallPrint: {
      as: 'p',
      fontSize: [1, 2, 3],
      fontFamily: fontFamilies.navigation,
      lineHeight: ['1.8rem', '1.8rem', '1.8rem'],
    },
    code: {
      as: 'span',
      px: '4rem',
      py: '2rem',
      lineHeight: '2.2rem',
      color: colours.maroonFlush,
      bg: colours.softPeach,
      borderRadius: 4,
      fontFamily: fontFamilies.code,
    },
    underline: {
      textDecoration: 'underline',
    },
  },
  fontSizes: [
    '1.2rem',
    '1.3rem',
    '1.4rem',
    '1.5rem',
    '1.6rem',
    '1.8rem',
    '2rem',
    '2.1rem',
    '2.2rem',
    '2.4rem',
    '2.6rem',
    '2.8rem',
    '3.2rem',
    '3.6rem',
    '5.2rem',
  ],
  // 1em is equal to 16px: 37.5em = 600px, 64em = 1024px
  // See note in ResetCss.js
  breakpoints: ['37.5em', '64em'],
  space: [
    0,
    '0.5rem',
    '1rem',
    '2rem',
    '2.5rem',
    '3rem',
    '3.5rem',
    '4rem',
    '4.5rem',
    '5rem',
  ],
  radius: [0, '2px', '4px', '6px', '8px', '10px', '22px'],
  colours: {
    ...colours,
  },
  linkStyle: {
    ...linkStyle,
  },
  fontFamilies: {
    ...fontFamilies,
  },
};