import styled from 'styled-components';
import {
  compose,
  space,
  lineHeight,
  fontSize,
  fontStyle,
  size,
  color,
  colorStyle,
  textStyle,
  fontFamily,
  fontWeight,
  letterSpacing,
  borderRadius,
} from 'styled-system';

const DynamicComponent = styled('div')(
  compose(
    space,
    lineHeight,
    fontSize,
    fontStyle,
    size,
    color,
    colorStyle,
    textStyle,
    fontFamily,
    fontWeight,
    letterSpacing,
    borderRadius
  )
);

export default DynamicComponent;