import React from "react"
import styled from "styled-components"
import Navbar from "../elements/Navbar"

const StyledHeader = styled.header`
  // background-color: green;
  // border: 2px solid teal;
`;


const Header = () => (
  <StyledHeader>
    <Navbar></Navbar>
  </StyledHeader>
);


export default Header;
