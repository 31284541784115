import styled from 'styled-components';
import {
  compose,
  space,
  layout,
  color,
  flexbox,
  grid,
  background,
  border,
  position,
} from 'styled-system';

const Box = styled('div')(
  compose(space, layout, color, flexbox, grid, background, border, position),
  { minWidth: 0 }
);

export default Box;