import React from "react"
import { createGlobalStyle } from "styled-components"

const ResetCss = createGlobalStyle`
  // Basic css reset
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
  }
  button {
    background-color: transparent;
    color: inherit;
    border-width: 0;
    padding: 0;
    cursor: pointer;
  }
  figure {
    margin: 0;
  }
  input::-moz-focus-inner {
    border: 0;
    padding: 0;
    margin: 0;
  }
  ul,
  ol,
  dd {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    font-size: inherit;
    font-weight: inherit;
  }
  p {
    margin: 0;
  }
  cite {
    font-style: normal;
  }
  fieldset {
    border-width: 0;
    padding: 0;
    margin: 0;
  }
  /* changing the font size of the html element detaches the meaning of rems in the styles from the meaning of rems in media queries:
  1rem in styles is now equivalent to 10px, while in media queries 1rem, and 1em for that matter, is still 16px.
  https://dev.to/danburzo/making-sense-of-css-media-queries-in-the-year-2019-52jj */
  html {
    /* 1rem = 10px */
    font-size: 62.5%;
  }
  html,
  body,
  #__gatsby {
    height: 100%;
    width: 100%;
  }
  body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    vertical-align: baseline;
    font-size: 1.6rem;
    font-weight: 400;
    /* line-height: 1.2; */
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
  }
`
export default ({ children }) => (
  <ResetCss />
)