import React from "react"
import { Link, graphql, StaticQuery } from 'gatsby';
import styled from "styled-components"
import Img from "gatsby-image"
import theme from "../general/theme"
const { gray2, gray7, gray8 } = theme.colours;


export default props => (
  <StaticQuery
  query={graphql`
    query MyQuery {
      allSanitySiteSettings {
        edges {
          node {
            logo {
              asset {
                fixed(width: 175) {
                  ...GatsbySanityImageFixed
                }
              }
            }
          }
        }
      }
    }
  `}
  render={data => <Navbar data={data} {...props} />}
  />
)
  
const StyledNavbar = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Left = styled.div`
  a {
    display: inline-block;
    padding: 1rem 2.5rem;
    margin: 1rem 0.5rem;
  }
  `;
  
  const Right = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  ul {
    display: flex;
    align-item: center;
  }
  
  li {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    a {
      display: inline-block;
      padding: 1rem 2.5rem;
      margin: 1rem 0.5rem;
      background-color: transparent;
      border-radius: 22px;
      font-size: 1.5rem;
      font-weight: 500;
      font-family: "Libre Franklin";
      color: ${gray7};
      &:hover {
        color: ${gray8};
        background-color: ${gray2};
      }
    }
  }
  
`;
  
const Navbar = ({data}) => (
  <StyledNavbar>
    <>
    <Left>
      <a href="/"><Img fixed={data.allSanitySiteSettings.edges[0].node.logo.asset.fixed} /></a>
    </Left>

    <Right>
      <ul>
        <li><Link to={`/`}>Home</Link></li>
        <li><Link to={`/research-projects/`}>Research<br />Projects</Link></li>
        <li><Link to={`/knowledge-translation/`}>Knowledge<br />Translation</Link></li>
        <li><Link to={`/knowledge-repository/`}>Knowledge<br />Repository</Link></li>
        <li><Link to={`/annual-reports-and-meeting-minutes/`}>Annual Reports<br />& Meeting Minutes</Link></li>
        <li><Link to={`/contact/`}>Contact</Link></li>
      </ul> 
    </Right>
    </>
   
  </StyledNavbar>
);
